import React, {useRef} from "react"; 
import './footer.css'
import {zoomIn} from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import { useInViewport } from 'react-in-viewport';

function FooterBody({count}){
  const styles = {
    zoomIn:{
      animation: 'x 1s',
      animationName: Radium.keyframes(zoomIn, 'zoomIn')
  }
  }
  return(
  <StyleRoot key={`footer${count}`}>
  <div class="container">
  <footer class="row row-cols-5 py-5 my-2 border-top" style={styles.zoomIn}>
    <div class="col mb-2 footer-col">
    <img src="./images/logo.png" loading="lazy" width="231" alt=""/>
    </div>
    
    <div class="col footer-col">
      <div class="title-wrapper">
    <span class="footer-title">MENU</span>
    </div>
      <ul class="nav flex-column">
        <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted footer-link">About</a></li>
        <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted footer-link">Portfolio</a></li>
        <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted footer-link">Blog</a></li>
        <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted footer-link">Contact</a></li>

      </ul>
    </div>

    <div class="col footer-col">
      <div class="title-wrapper">
    <span class="footer-title">SERVICES</span>
    </div>
      <ul class="nav flex-column">
        <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted footer-link">Design</a></li>
        <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted footer-link">Development</a></li>
        <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted footer-link">Document</a></li>
        <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted footer-link">Contact</a></li>

      </ul>
    </div>

    
    <div class="col footer-col">
      <div class="title-wrapper">
    <span class="footer-title">Social</span>
    </div>
    <div class="footer-social">
          <div>
               <img src="./images/facebook.png" loading="lazy" width="48" alt=""/>
          </div>
          <div>
               <img src="./images/twitter.png" loading="lazy" alt=""/>
          </div>
          <div>
               <img src="./images/instagram.png" loading="lazy" alt=""/>
          </div>
          </div>
    </div>
  </footer>
</div>
</StyleRoot>
  )
}
function Footer() { 
  const footerRef = useRef();
  const {
    inViewport,
    enterCount,
    leaveCount,
  } = useInViewport(
    footerRef,
  {},
  { disconnectOnLeave: false },
  {}
  );
    return (
      <section >
          <div ref={footerRef}></div>
          {enterCount>=1 &&<FooterBody count={2}/>}
          {enterCount<1 &&<FooterBody count={enterCount}/>}
      </section>
  ) }

  
export default Footer;
import React, {useRef} from "react"; 
import {fadeInRight} from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import { useInViewport } from 'react-in-viewport';
import "./../home.css"

function ContactUsBody({count}){
    const styles = {
        fadeInRight: {
          animation: 'x 1s',
          animationName: Radium.keyframes(fadeInRight, 'fadeInRight')
        },
    }
    return(
        <StyleRoot key={`ContactUs${count}`}>
              <div class="about-container">
                                    <img src="./images/contact_us.png" class="about-image"/>
                                <div class="about-image-text" >
                                
                                    <h1 class="about-text" style={styles.fadeInRight} >
                                    For us, partnership is a central component of our management
                                    </h1>
                                    <div>
                                    <button class="btn-contact-us" style={styles.fadeInRight} >Contact Us</button>
                                    </div>
                                </div>
                            </div>
                            
        </StyleRoot>
    )
}

function ContactUs() { 
    const contactUsRef = useRef();
    const {
      inViewport,
      enterCount,
      leaveCount,
    } = useInViewport(
        contactUsRef,
    {},
    { disconnectOnLeave: false },
    {}
    );
      return (
        <section >
            <div ref={contactUsRef}></div>
            {enterCount>=1 &&<ContactUsBody count={2}/>}
            {enterCount<1 &&<ContactUsBody count={enterCount}/>}
        </section>
    ) }

    export default ContactUs;
import React, {useRef} from "react"; 
import {fadeInLeft, fadeInUp, fadeInRight} from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import { useInViewport } from 'react-in-viewport';
import "./../home.css"

function VisionBody({count}){
    const styles = {
        fadeInLeft: {
          animation: 'x 1s',
          animationName: Radium.keyframes(fadeInLeft, 'fadeInLeft')
        },
        fadeInUp:{
            animation: 'x 1s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
        },
        fadeInRight: {
            animation: 'x 1s',
            animationName: Radium.keyframes(fadeInRight, 'fadeInRight')
          },
    }
    return(
        <StyleRoot key={`vision${count}`}>
            <div class="box3-container" id='vision-section'>
            <div class="sec-5">
            <div style={styles.fadeInLeft}>
            <div>
            <img src="./images/vision.png" loading="lazy" alt=""/>
            <span style={{ fontSize:"20px", marginLeft:"20px",letterSpacing:"0.1em"}}>VISION</span>
            </div>
            </div>
            <div class="devise-wrapper">
            <span style={{fontSize:"3em",fontWeight:100, letterSpacing:"0.1em", textAlign:"start",...styles.fadeInLeft}}>DEVISE. DESIGN. DELIVER</span>
             <button class="btn-see-all" style={{marginLeft:"4rem",...styles.fadeInRight}}>See All
                        </button>
                </div>
                        <div class="image-container" style={styles.fadeInUp}>
                            <div class="ddd-card">
                            <img src="./images/devise.png" loading="lazy" width="85" alt="" class="icon-image"/>
                            <h3 class="ddd-card-header">Devise</h3>
                            <p class="ddd-card-description">We discuss the ideas discuss the thought about some innovative idea and we bring it to a sustainable product.</p>
                            </div>
                            <div class="ddd-card">
                            <img src="./images/design.png" loading="lazy" width="80" alt="" class="icon-image"/>
                            <h3 class="ddd-card-header">Design</h3>
                            <p class="ddd-card-description">I strive for two things in design: simplicity and clarity. Great design is born of those two things.</p>
                            </div>
                            <div class="ddd-card">
                            <img src="./images/deliver.png" loading="lazy" width="80" alt="" class="icon-image"/>
                            <h3 class="ddd-card-header">Deliver</h3>
                            <p class="ddd-card-description">This "together" is a promise we give to our partner companies and customers but as well to ourselves. We work together, learn from each other and develop together.</p>
                            </div>
                        </div>
            </div>
           </div>
        </StyleRoot>
    )
}

function Vision() { 
    const visionRef = useRef();
    const {
      inViewport,
      enterCount,
      leaveCount,
    } = useInViewport(
        visionRef,
    {},
    { disconnectOnLeave: false },
    {}
    );
      return (
        <section >
            <div ref={visionRef}></div>
            {enterCount>=1 &&<VisionBody count={2}/>}
            {enterCount<1 &&<VisionBody count={enterCount}/>}
        </section>
    ) }

    export default Vision;
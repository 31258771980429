import React from 'react';
import Navbar from '../../components/navbar/Navbar';
import './home.css';
import { fadeInLeft, zoomIn, fadeInRight, fadeInUp } from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import About from './home-components/About';
import Vision from './home-components/Vision';
import Competencies from './home-components/Competencies';
import Business from './home-components/Business';
import Brand from './home-components/Brand';
import News from './home-components/News';
import ContactUs from './home-components/ContactUs';

const Home=()=>{
    const styles = {
        fadeInLeft: {
          animation: 'x 1s',
          animationName: Radium.keyframes(fadeInLeft, 'fadeInLeft')
        },
        zoomIn:{
            animation: 'x 1s',
            animationName: Radium.keyframes(zoomIn, 'zoomIn')
        },
        fadeInRight:{
            animation: 'x 1s',
            animationName: Radium.keyframes(fadeInRight, 'fadeInRight')
        },
        fadeInUp:{
            animation: 'x 1s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
        }
      }

    return(
        
        <div>
            <StyleRoot >
             <div class="box1-container">
             
                <div class="sec-1">
                    <Navbar/>
                    
                    <div class="sec1-content">
                    
                        <div style={styles.fadeInLeft}>
                            <img src="./images/manufacturing.png" loading="lazy" alt=""/>
                            <span style={{marginLeft: "16px", fontSize:"20px", letterSpacing:"0.1em"}} >MANUFACTURING</span>
                        </div>
                        <div style={styles.fadeInLeft}>
                        <h1 class="sec1-header">Manufacturing Solutions</h1>
                       <div class="sec1-text-wrapper">
                            <p style={{fontSize:"1.2em", fontWeight:"400", letterSpacing:"0.1em", textAlign:"start"}}>And of a deep dark blue spectacle before us was indeed sublime.</p>
                        </div>
                        </div>
                        <div style={styles.fadeInLeft}>
                        <button class="btn-explore">Explore More</button>
                       
                    </div>
                    </div>
                  
                    
                </div>
                
                
                <div class="sec2">
                
                    <div class="video-item">
                        <video id="bgvideo" autoPlay loop style={{"background-image":"url(&quot;https://uploads-ssl.webflow.com/6381af428ae82d3de54749ac/6385a6a64c1c9a6fcfd23614_Time Lapse Video of Tall Buildings-poster-00001.jpg&quot;)"}} muted playsInline data-wf-ignore="true" data-object-fit="fill">
                        <source src="./videos/video.mp4" data-wf-ignore="true"/>
                        <source src="https://uploads-ssl.webflow.com/6381af428ae82d3de54749ac/6385a6a64c1c9a6fcfd23614_Time Lapse Video of Tall Buildings-transcode.webm" data-wf-ignore="true"/>
                        </video>
                    </div>
                   

                </div>
             
             
             
             </div>
           
             </StyleRoot>
            <div>
                <About/>
           </div>
          
           
           
           <div>
            <Vision/>
           </div>
                    <div>
                        <Competencies/>
                    </div>
                    <div class="empty-box"></div>
                        <div>
                            <Business/>
                        </div>

                            <div>
                                <Brand/>
                            </div>
                            <div>
                                <News/>
                            </div>
                            <div>
                                <ContactUs/>
                            </div>
                           </div>
    

    )
}
export default Home;
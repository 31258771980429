import React, {useRef} from "react"; 
import {fadeInLeft, zoomIn} from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import { useInViewport } from 'react-in-viewport';
import "./../home.css"

function AboutBody({count}){
    const styles = {
        fadeInLeft: {
          animation: 'x 1s',
          animationName: Radium.keyframes(fadeInLeft, 'fadeInLeft')
        },
        zoomIn:{
            animation: 'x 1s',
            animationName: Radium.keyframes(zoomIn, 'zoomIn')
        },
    }
    return(
        <StyleRoot key={`about${count}`}>
            <div  class="box2-container" id='about-section'>
            <div class="sec-3" style={styles.zoomIn}>
                <img src="./images/about_banner.png" class="sec3-image" alt="" />
            </div>
       
            <div class="sec-4">
            <div style={styles.fadeInLeft}>
              <div>
                <img src="./images/about.png" loading="lazy" width="42" alt=""/>
                <span style={{marginLeft: "16px", fontSize:"20px", letterSpacing:"0.1em"}}>ABOUT</span>
                </div>
                </div>
                <div style={styles.fadeInLeft}>
                <h1 style={{fontSize:"2.6em",fontWeight:"100",letterSpacing:"0.1em",marginTop:"10px", color:"#333", paddingRight:"30px"}}>Highest Quality Consumer Products</h1>
                </div>
                <div style={styles.fadeInLeft}>
            <div style={{marginTop:"10px"}}>
                            <p style={{fontSize:"1.2em", color:"#585858", fontWeight:"300", letterSpacing:"0.1em", borderLeft:"2px solid #1e4e90", paddingLeft:"1.5rem", paddingRight:"16px"}}>And of a deep dark blue spectacle before us was indeed sublime.</p>
                        </div>
                        </div>
                        <div style={styles.fadeInLeft}>
                        <p style={{fontSize:"1.2em", color:"#a1a1a1", fontWeight:"300", letterSpacing:"0.1em"}}>For almost 3 decades we, smith, have been proving thorite sense for trends both nationally and internationally in the field of product development</p>
                        </div>
                        <div style={styles.fadeInLeft}>
                        <button class="btn-explore" style={{border:"2px solid #335f9a"}}>About Us
                        </button>
                        </div>
                    </div>
                    </div>
        </StyleRoot>
    )
}

function About() { 
    const aboutRef = useRef();
    const {
      inViewport,
      enterCount,
      leaveCount,
    } = useInViewport(
        aboutRef,
    {},
    { disconnectOnLeave: false },
    {}
    );
      return (
        <section >
            <div ref={aboutRef}></div>
            {console.log("count=", enterCount)}
            {enterCount>=1 &&<AboutBody count={2}/>}
            {enterCount<1 &&<AboutBody count={enterCount}/>}
        </section>
    ) }

    export default About;
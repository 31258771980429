import React, {useRef} from "react"; 
import {zoomIn} from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import { useInViewport } from 'react-in-viewport';
import "./../home.css"

function BrandBody({count}){
    const styles = {
        zoomIn: {
          animation: 'x 1s',
          animationName: Radium.keyframes(zoomIn, 'zoomIn')
        },
    }
    return(
        <StyleRoot key={`brand${count}`}>
            <div class="brand-container">
                <h4 class="brand-header">Partner Brands </h4>
                <div class="brand-image-container" style={styles.zoomIn}>
                             <div class="brand-image">
                                <img src="./images/fosun.png" style={{borderRadius:"8px"}}/>
                             </div>
                             <div class="brand-image">
                             <img src="./images/amazon.png" style={{borderRadius:"8px"}}/>
                             </div>
                             <div class="brand-image">
                             <img src="./images/flipkart.png" style={{borderRadius:"8px"}}/>
                             </div>
                             <div class="brand-image">
                             <img src="./images/ajio.png" style={{borderRadius:"8px"}}/>
                             </div>
                             <div class="brand-image">
                             <img src="./images/cliq.png" style={{borderRadius:"8px"}}/>
                             </div>
                    </div>
                </div>
        </StyleRoot>
    )
}

function Brand() { 
    const brandRef = useRef();
    const {
      inViewport,
      enterCount,
      leaveCount,
    } = useInViewport(
        brandRef,
    {},
    { disconnectOnLeave: false },
    {}
    );
      return (
        <section >
            <div ref={brandRef}></div>
            {enterCount>=1 &&<BrandBody count={2}/>}
            {enterCount<1 &&<BrandBody count={enterCount}/>}
        </section>
    ) }

    export default Brand;
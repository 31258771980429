import React, {useRef} from "react"; 
import {fadeInLeft,fadeInUp} from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import { useInViewport } from 'react-in-viewport';
import "./../home.css"

function NewsBody({count}){
    const styles = {
        fadeInLeft : {
          animation: 'x 1s',
          animationName: Radium.keyframes(fadeInLeft, 'fadeInLeft')

        },
        fadeInUp : {
            animation: 'x 1s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
  
          },
    }
    return(
        <StyleRoot key={`news${count}`}>
         <div class="news-container" >
                                
                                <div style={styles.fadeInLeft}>
                                <img src="./images/news.svg" />
                                <span style={{marginLeft:"1em",fontSize:"1.15rem"}}>NEWS</span>
                                </div>
                                <h3 class="news-header" style={styles.fadeInLeft}>Check Our Inside Stories</h3>
                                <div class="news-image" style={styles.fadeInUp}>
                                <div class="news-card">
                                    <img src="./images/news1.png" class="news-img"/>
                                    <p class="news-date">18 aug 2020</p>
                                    <h4 class="news-text">Foundation upon which the rest of the project</h4>
                                    <button class="news-btn-see-all" >See All </button>
                                    </div>
                                    <div class="news-card">
                                    <img src="./images/news2.jpg" class="news-img"/>
                                    <p class="news-date">18 aug 2020</p>
                                    <h4 class="news-text">Foundation upon which the rest of the project</h4>
                                    <button class="news-btn-see-all" >See All </button>
                                    </div>
                                    <div class="news-card">
                                    <img src="./images/news3.jpg" class="news-img"/>
                                    <p class="news-date">18 aug 2020</p>
                                    <h4 class="news-text">Foundation upon which the rest of the project</h4>
                                    <button class="news-btn-see-all">See All </button>

                                    </div>
                                </div>
                            </div>
        </StyleRoot>
    )
}

function News() { 
    const newsRef = useRef();
    const {
      inViewport,
      enterCount,
      leaveCount,
    } = useInViewport(
        newsRef,
    {},
    { disconnectOnLeave: false },
    {}
    );
      return (
        <section >
            <div ref={newsRef}></div>
            {enterCount>=1 &&<NewsBody count={2}/>}
            {enterCount<1 &&<NewsBody count={enterCount}/>}
        </section>
    ) }

    export default News;
import React, {useRef} from "react"; 
import {fadeInLeft} from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import { useInViewport } from 'react-in-viewport';
import "./../home.css"

function BusinessBody({count}){
    const styles = {
        fadeInLeft: {
          animation: 'x 1s',
          animationName: Radium.keyframes(fadeInLeft, 'fadeInLeft')
        },
    }
    return(
        <StyleRoot key={`business${count}`}>
            <div class="box5-container">
                        <div class="image-card">
                            
                            <img src="./images/business_banner.png" style={{width:"100%"}}/>
                        
                        </div>
                        <div class="business-card" >
                        <div style={styles.fadeInLeft}>
                          <div class="text-business-container">
                            <img src="./images/business.png"  />
                            <span style={{marginLeft:"1em",fontSize:"1.2rem"}}>BUSINESS</span>
                          </div>
                        </div>
                        <div style={styles.fadeInLeft}>
                        <h1 class="business-header" >Reach every part of India with us!</h1>
                        </div>
                        <div style={styles.fadeInLeft}>
                        <p style={{fontSize:"1.2em", fontWeight:"400", letterSpacing:"0.1em", borderLeft:"4px solid #1e4e90", paddingLeft:"0.8rem", paddingRight:"4em",marginTop:"2.5em",textAlign:"start"}}>Cloudless and of a deep dark blue spectacle before us was indeed.</p>
                       </div>
                      
                        <div class="business-card1" style={styles.fadeInLeft}>
                            <div class="dre-card">
                                <p class="dre-header">DISTRIBUTION</p>
                                <p class="dre-text">With the ability to supply retail partners within 24-48 hours, we’ve established ourselves as an unrivalled leader in pan India distribution.</p>
                            </div>
                            <div class="dre-card">
                            <p class="dre-header">RETAIL</p>
                            <p class="dre-text">We bring the story of our brands to life by collaborating with retail partners.</p>
                            </div>
                            <div class="dre-card">
                            <p class="dre-header">ECOMMERCE</p>
                            <p class="dre-text">We enable the online success of the luxury beauty brands with our presence on the most popular e-commercesites.</p>
                            </div>
                            </div>
                            <div style={styles.fadeInLeft}>
                        <button class="btn-service">Our service</button>
                        </div>
                        </div>
                     </div>
        </StyleRoot>
    )
}

function Business() { 
    const businessRef = useRef();
    const {
      inViewport,
      enterCount,
      leaveCount,
    } = useInViewport(
        businessRef,
    {},
    { disconnectOnLeave: false },
    {}
    );
      return (
        <section >
            <div ref={businessRef}></div>
            {enterCount>=1 &&<BusinessBody count={2}/>}
            {enterCount<1 &&<BusinessBody count={enterCount}/>}
        </section>
    ) }

    export default Business;
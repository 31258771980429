import React from 'react';
import './App.css';
import Footer from './components/footer/Footer';
import Home from './screens/home-screen/Home';

function App() {
  return (
    <div className="App">
      <Home/>
      <Footer/>
    </div>
    
  );
}

export default App;

import React from 'react'
import './Navbar.css'


const Navbar=()=>{
    return(
        <div>
        <nav class="navbar navbar-expand-lg navbar-light bg-light rounded">
        <a class="navbar-brand nav-header" href="#">
          <img src="./images/logo.png" loading="lazy" width="171" height="83" alt="" class="image"/>
        </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

        <div class="collapse navbar-collapse nav-elements" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <a class="nav-link nav-text" href="#about-section">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-text" href="#competencies-section">Competencies</a>
            </li>

            <li class="nav-item">
              <a class="nav-link nav-text" href="#vision-section">Vision</a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-text" href="#">Blog</a>
            </li>
           
          </ul>
        </div>
      </nav> 
        </div>
    )
}
export default Navbar;
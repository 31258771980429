import React, {useRef} from "react"; 
import {fadeInLeft, fadeInUp} from 'react-animations'
import Radium, {StyleRoot} from 'radium';
import { useInViewport } from 'react-in-viewport';
import "./../home.css"

function CompetenciesBody({count}){
    const styles = {
        fadeInLeft: {
          animation: 'x 1s',
          animationName: Radium.keyframes(fadeInLeft, 'fadeInLeft')
        },
        fadeInUp:{
            animation: 'x 1s',
            animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
        },

    }
    return(
        <StyleRoot key={`competencies${count}`}>
            <div class="box4-container" id='competencies-section'>
                    <div style={styles.fadeInLeft}>
                        <div style={{marginTop:"2em"}}>
                        <img src="./images/competencies.png" loading="lazy" alt=""/>
                        <span style={{marginLeft:"1em", fontSize:"1.2rem"}}>COMPETENCIES</span>
                        </div>
                        </div>
                        <div style={styles.fadeInLeft}>
                        <h4 style={{marginTop: "1em", fontWeight:"lighter"}}>SMITHRETAIL IS MORE THAN A DISTRIBUTION PARTNER. WE MAKE BRANDS.</h4>
                        </div>
                        <div>
                        <button class="btn-explore">Explore All</button>
                        </div>
                        <div class="competencies-card-wrapper" style={styles.fadeInUp}>
                        <div class="competencies-card" >
                            <img src="./images/retail_n_distribution.png" class="competencies-image" alt=""/>
                            <div class="text">
                                <p class="competencies-header">Retail and distribution</p>
                                <p class="competencies-text">Smith retail has an extremely efficient logistics supply chain in place. We have a specialized e-logistics branch that is a dedicated supply chain for our e-commerce business for our renowned brands over a large area covering more than a thousand cities and towns. Our international partners tested our competency before joining hands with us to promote their brands here in India.</p>
                            </div>
                        </div>
                        <div class="competencies-card" id="corss-border-wrapper">
                        <img src="./images/cross_border_ecommerce.png" class="competencies-image" id="corss-border-img"/>
                        <div class="text">
                            <p class="competencies-header">Cross border e-commerce</p>
                            <p class="competencies-text">Being an E-commerce firm, we have charted out new-generation integrated customerservice. We have trained professionals who adhere to service concepts beyondcustomer care, after-sales service, and call center helplines. We have broughtsomething new to the table by indulging in customer consultation and satisfyingevery customer requirement and speedy complaint redressal procedure.</p>
                        </div>
                        </div>
                        <div class="competencies-card" id="online-store-wrapper">
                        <img src="./images/online_store_operation.png" class="competencies-image"  id="online-store-img"/>
                        <div class="text">
                            <p class="competencies-header">Online store operation technology service</p>
                            <p class="competencies-text">We worked hard on the online operations to give our customers a seamless, uninterrupted service. Our firm provide various user-friendly options that include technology-enabled operations, self-owned online stores, touchpoint development, and customized designs. Since we manage our business intelligence and data visualization services well, we can gauge market trends and apply them to our operations, benefitting the customers.</p>
                        </div>
                        </div>
                        <div class="competencies-card">
                        <img src="./images/marketing.png" class="competencies-image"/>
                        <div class="text">
                            <p class="competencies-header">Marketing</p>
                            <p class="competencies-text">Our job is about providing an inclusive experience and saleable ideas for our product. Marketing involves understanding customer behavior and analyzing the data, which gives us ample scope to make business strategies. We find it easier to work with data using the latest technology to better understand the customer life cycle.</p>
                        </div>
                        </div>
                        </div>
                    </div>
        </StyleRoot>
    )
}

function Competencies() { 
    const competenciesRef = useRef();
    const {
      inViewport,
      enterCount,
      leaveCount,
    } = useInViewport(
        competenciesRef,
    {},
    { disconnectOnLeave: false },
    {}
    );
      return (
        <section >
            <div ref={competenciesRef}></div>
            {enterCount>=1 &&<CompetenciesBody count={2}/>}
            {enterCount<1 &&<CompetenciesBody count={enterCount}/>}
        </section>
    ) }

    export default Competencies;